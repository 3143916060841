import React from 'react';
import {Link} from 'react-router-dom';
import authRoutes from '../auth_routes';


const MfaResetBadLink = (props) => {
    return (
        <div className='auth-narrow'>
            <div className='sd-card sd-elevation-1 auth-card'>
                <h1>
                    Invalid 2-step verification reset link
                </h1>
                <Link className='anchor-button' to={authRoutes.AUTH_ROUTES.SIGN_IN}>
                    <button className={'sd-button mod-button-wide'}>Try again</button>
                </Link>
            </div>
        </div>
    );
};

MfaResetBadLink.propTypes = {};

MfaResetBadLink.defaultProps = {};

export default MfaResetBadLink;
