import React from 'react';
import ReactDOM from 'react-dom';
import {setup} from './index.js';
import {AuthMainWrapper} from './components/auth_main.jsx';

const renderAuth = ({
    googleClientId,
    appleClientId,
    showEmailOptIn,
    localizedTOS,
    showAppDownload,
    showKitImage,

    appleImgUrl,
    appStoreImgUrl,
    closeIconUrl,
    googleImgUrl,
    googlePlayImgUrl,
    kitImgUrl,
    resendOtpUrl,
    starIconUrl,

    csrfToken,
}) => {
    setup({csrfToken});

    ReactDOM.render(
        <AuthMainWrapper
            googleClientId={googleClientId}
            appleClientId={appleClientId}
            showEmailOptIn={showEmailOptIn}
            localizedTOS={localizedTOS}
            showAppDownload={showAppDownload}
            showKitImage={showKitImage}

            appleImgUrl={appleImgUrl}
            appStoreImgUrl={appStoreImgUrl}
            closeIconUrl={closeIconUrl}
            googleImgUrl={googleImgUrl}
            googlePlayImgUrl={googlePlayImgUrl}
            kitImgUrl={kitImgUrl}
            resendOtpUrl={resendOtpUrl}
            starIconUrl={starIconUrl}
        />,
        document.getElementById('auth-main')
    );
};

const ttamAuth = {renderAuth};
window.ttam_auth = ttamAuth;
