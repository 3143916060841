import React, {useEffect, useLayoutEffect, useState} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
    useLocation,
    Redirect
} from 'react-router-dom';
import ssoUtils from '../ssoUtils.js';
import {VerifyEmail} from './verify_email.jsx';
import LinkGoogleSsoForm from './link_google_sso_form.jsx';
import LinkAppleSsoForm from './link_apple_sso_form.jsx';
import {GoogleSignup} from './google_signup_form.jsx';
import {SignUpFooter} from './signup_footer.jsx';
import SignInForm from './signin_form.jsx';
import {SignInFooter} from './signin_footer.jsx';
import {TtamSignup} from './ttam_signup.jsx';
import {AppleSignup} from './apple_signup_form.jsx';
import {SsoMutexPage} from './sso_mutex_page.jsx';
import {AppleSsoRelayEmailWarning} from './apple_sso_relay_email_warning.jsx';
import authRoutes from '../auth_routes.js';
import MfaTotpForm from './mfa_totp.jsx';
import {SsoSignin} from './sso_signin.jsx';
import {PasswordReset} from './password_reset.jsx';
import {SimpleSigninFooter} from './simple_signin_footer.jsx';
import {PasswordResetVerify} from './password_reset_verify.jsx';
import scriptUtils from '../script_utils.js';
import {CookiesDisabledPage} from './cookies_disabled_page.jsx';
import {getEmbeddedNextSearchParam, getNextUrl} from '../utils.jsx';
import MfaTotpReset from './mfa_reset.jsx';
import MfaResetSent from './mfa_reset_sent.jsx';
import MfaResetSignInForm from './mfa_reset_signin.jsx';
import MfaResetBadLink from './mfa_reset_bad_link.jsx';
import ConfirmAccountSignInForm from './confirm_account_signin.jsx';


const AuthMain = (props) => {
    const history = useHistory();
    const currLocation = useLocation();

    const [email, setEmail] = useState();
    const [verifyPageLoginUrl, setVerifyPageLoginUrl] = useState();
    const [ssoFirstName, setSsoFirstName] = useState();
    const [ssoLastName, setSsoLastName] = useState();
    const [selectedSso, setSelectedSso] = useState();
    const [googleAuth, setGoogleAuth] = useState();
    const [googleSsoButtonEnabled, setGoogleSsoButtonEnabled] = useState(true);
    const [ssoErrors, setSsoErrors] = useState([]);


    /* ttam.oidc_provider is hard coded to route to /login so if a relying party wants to take
     * a user to a different page, we have to write our own logic. To get data from the RP to auth,
     * we use the authentication_url method in ttam.api to generate an auth/authorize URL with
     * a custom get parameter. The flow is
     *
     * rp/someurl
     * auth/authorize?custom_param=custom_value
     * auth/login?next=encoded(auth/authorize?custom_param=custom_value)
     *
     * When the user lands on login, that custom parameter is in the get parameter of the authorize
     * url which itself is an encoded get parameter of login. That's why we have to parse the
     * next parameter.
     */
    const doManualRedirections = () => {
        if (currLocation.pathname == authRoutes.AUTH_ROUTES.SIGN_IN) {
            const authorizeUrl = getNextUrl(currLocation.search);
            const authorizeSearchParams = getEmbeddedNextSearchParam(currLocation.search);
            if (!authorizeUrl || authorizeUrl.pathname != '/authorize/' ||
                !authorizeSearchParams || authorizeSearchParams.get('goto_signup') != 1) {
                return;
            }
            // delete goto_signup so that we can go from signup back to login
            authorizeSearchParams.delete('goto_signup');
            authorizeUrl.search = authorizeSearchParams.toString();
            const searchParams = new URLSearchParams(currLocation.search);
            searchParams.set('next', authorizeUrl.toString());
            pushHistory(authRoutes.AUTH_ROUTES.SIGN_UP, `?${searchParams.toString()}`);
        }
    };
    const catchReferenceError = (err) => {
        if (!(err instanceof ReferenceError)) {
            throw err;
        }
    };

    useEffect(() => {
        doManualRedirections();
        // TODO I think a lot of this SSO stuff should just be moved into sso_buttons.jsx
        try {
            setGoogleAuth(
                ssoUtils.initGoogleSso(props.googleClientId, googleSigninCallback, googleSigninErrorCallback));
        } catch (err) {
            catchReferenceError(err);
        }
    }, []);

    const pushHistory = (target, getParams=null) => {
        authRoutes.redirectToRoute(history, target, getParams);
    };

    const googleSigninErrorCallback = (error) => {
        setGoogleSsoButtonEnabled(true);
        setSsoErrors([error.message]);
    };
    const googleSigninCallback = (authResult) => {
        if (!authResult['code']) {
            return;
        }
        return $.post({
            url: `/google_token/${window.location.search}`,
            data: {google_code: authResult['code']},
        }).then((data) => {
            if (data.user_exists && data.google_oauth_enabled) {
                // existing apple sso user, backend has logged in
                window.location.href = data.redirect;
            } else if (data.apple_oauth_enabled) {
                // is apple user, cannot user google with apple
                pushHistory(authRoutes.AUTH_ROUTES.SSO_MUTEX);
            } else if (data.user_exists) {
                // existing, non SSO user
                pushHistory(authRoutes.AUTH_ROUTES.GOOGLE_SSO.LINK);
            } else {
                // existing, non SSO user
                setSsoFirstName(data.first_name);
                setSsoLastName(data.last_name);
                pushHistory(authRoutes.AUTH_ROUTES.GOOGLE_SSO.SIGN_UP);
            }
        }).catch(() => {
            googleSigninErrorCallback({message: 'An unexpected error has occurred. Please try again later.'});
        });
    };
    const appleSigninCallback = (authResult) => {
        if (!authResult.authorization.code) {
            return;
        }
        return $.post({
            url: `/apple_token/${window.location.search}`,
            data: {
                code: authResult.authorization.code,
                user: authResult.user,
                redirectUri: `${window.location.origin}/apple_token/`,
            },
        }).then((data) => {
            if (data.user_exists && data.apple_oauth_enabled) {
                // existing apple sso user, backend has logged in
                window.location.href = data.redirect;
            } else if (data.google_oauth_enabled) {
                // is google user, cannot user apple with google
                pushHistory(authRoutes.AUTH_ROUTES.SSO_MUTEX);
            } else if (data.user_exists) {
                // existing, non SSO user
                pushHistory(authRoutes.AUTH_ROUTES.APPLE_SSO.LINK);
            } else {
                // new user, create new account from SSO
                setSsoFirstName(data.first_name);
                setSsoLastName(data.last_name);
                if (data.is_private_email) {
                    // warn user the consequences of using the relay email
                    pushHistory(authRoutes.AUTH_ROUTES.APPLE_SSO.RELAY_EMAIL_WARNING);
                } else {
                    pushHistory(authRoutes.AUTH_ROUTES.APPLE_SSO.SIGN_UP);
                }
            }
        }); // error handling is done in component
    };

    const handleGoogleSso = () => {
        // AUTH-1112 we cannot lazy load google SSO because GSO needs to call init() and then
        // grantOfflineAccess. If we lazy load and do them together on button click,
        // the Safari popup blocker thinks the popup open action is no longer tied to the
        // button click and decides to block the popup.
        setSelectedSso('Google');
        googleAuth.requestCode();
        setGoogleSsoButtonEnabled(false);
    };

    const handleAppleSso = () => {
        setSelectedSso('Apple');
        return scriptUtils.loadScript(scriptUtils.APPLE_SSO_SCRIPT)
            .then(() => {
                ssoUtils.initAppleSso(props.appleClientId);
                return ssoUtils.startSignInWithApple();
            }).then(appleSigninCallback);
    };

    const narrowWrap = (innerElem, footer = null) => {
        return (
            <div className='auth-narrow'>
                <div className='sd-card sd-elevation-1 auth-card' >
                    {innerElem}
                </div>
                {footer}
            </div>
        );
    };

    let assignedSso = null;
    if (selectedSso === 'Google') {
        assignedSso = 'Apple';
    } else if (selectedSso === 'Apple') {
        assignedSso = 'Google';
    }

    useLayoutEffect(() => {
        if (!navigator.cookieEnabled) {
            history.push(authRoutes.AUTH_ROUTES.COOKIES_ERROR);
        }
    }, []);

    return (
        <Switch>
            <Route path={authRoutes.AUTH_ROUTES.COOKIES_ERROR}>
                {narrowWrap(
                    <CookiesDisabledPage
                        onCookiesEnabled={() => authRoutes.redirectToLogin(history)}
                    />,
                    <SignInFooter />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.SIGN_IN}>
                {narrowWrap(
                    <SignInForm
                        googleImgUrl={props.googleImgUrl}
                        appleImgUrl={props.appleImgUrl}
                        onGoogleSsoClick={handleGoogleSso}
                        googleSsoButtonEnabled={googleSsoButtonEnabled}
                        onAppleSsoClick={handleAppleSso}
                        onForgotPasswordClick={() => {
                            history.push(authRoutes.AUTH_ROUTES.FORGOT);
                        }}
                        ssoErrors={ssoErrors}
                        onSuccessfulSubmit={(data) => {
                            if (data.mfa_device_type) {
                                history.push({
                                    pathname: authRoutes.AUTH_ROUTES.MFA_TOTP,
                                    search: data.get_params,
                                    state: {'mfa_type': data.mfa_device_type},
                                });
                            } else {
                                window.location.href = data.url;
                            }
                        }}
                    />,
                    <SignInFooter />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.SIGN_UP}>
                <TtamSignup
                    showKitImage={props.showKitImage}
                    starIconUrl={props.starIconUrl}
                    closeIconUrl={props.closeIconUrl}
                    appStoreImgUrl={props.appStoreImgUrl}
                    googlePlayImgUrl={props.googlePlayImgUrl}
                    kitImgUrl={props.kitImgUrl}
                    googleImgUrl={props.googleImgUrl}
                    appleImgUrl={props.appleImgUrl}
                    onGoogleSsoClick={handleGoogleSso}
                    onAppleSsoClick={handleAppleSso}
                    googleSsoButtonEnabled={googleSsoButtonEnabled}
                    ssoErrors={ssoErrors}
                    localizedTOS={props.localizedTOS}
                    showEmailOptIn={props.showEmailOptIn}
                    onSuccessfulSubmit={(data) => {
                        setEmail(data.email);
                        setVerifyPageLoginUrl(data.login_url);
                        history.push(authRoutes.AUTH_ROUTES.VERIFY);
                    }}
                />
            </Route>
            <Route
                path={authRoutes.AUTH_ROUTES.MFA_TOTP}
                component={
                    () => <MfaTotpForm
                        mfa_type={history.location.state.mfa_type}
                        resendOtpUrl={props.resendOtpUrl}
                    />
                }
            />
            <Route path={[authRoutes.AUTH_ROUTES.FORGOT, authRoutes.AUTH_ROUTES.PASSWORD_RESET]}>
                {narrowWrap(
                    <PasswordReset
                        onSuccessfulSubmit={(data) => {
                            setEmail(data.email);
                            history.push(authRoutes.AUTH_ROUTES.FORGOT_VERIFY);
                        }}
                    />,
                    <SimpleSigninFooter />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.MFA_RESET}>
                <MfaTotpReset onSuccessfulSubmit={() => {
                    history.push(authRoutes.AUTH_ROUTES.MFA_RESET_SENT);
                }} />
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.MFA_RESET_SENT}>
                <MfaResetSent />
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.MFA_RESET_SIGNIN}>
                {
                    narrowWrap(
                        <MfaResetSignInForm onBadToken={() => {
                            history.push(authRoutes.AUTH_ROUTES.MFA_RESET_BAD_LINK);
                        }} />,
                        <SimpleSigninFooter />
                    )
                }
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.MFA_RESET_BAD_LINK}>
                <MfaResetBadLink />
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.CONFIRM_ACCOUNT_SIGNIN}>
                {
                    narrowWrap(
                        <ConfirmAccountSignInForm />,
                        <SimpleSigninFooter />
                    )
                }
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.FORGOT_VERIFY}>
                {narrowWrap(
                    <PasswordResetVerify
                        email={email}
                        anonUserRedirect={() => {
                            authRoutes.redirectToLogin(history);
                        }}
                    />,
                    <SimpleSigninFooter />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.GOOGLE_SSO.SIGN_IN}>
                {narrowWrap(
                    <SsoSignin
                        ssoProvider='Google'
                        onClickCta={handleGoogleSso}
                        onClickBack={() => {
                            pushHistory(authRoutes.AUTH_ROUTES.SIGN_IN);
                            return false;
                        }}
                        buttonImgUrl={props.googleImgUrl}
                        googleSsoButtonEnabled={googleSsoButtonEnabled}
                        ssoErrors={ssoErrors}
                    />,
                    <SignInFooter />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.APPLE_SSO.SIGN_IN}>
                {narrowWrap(
                    <SsoSignin
                        ssoProvider='Apple'
                        onClickCta={handleAppleSso}
                        onClickBack={() => {
                            pushHistory(authRoutes.AUTH_ROUTES.SIGN_IN);
                            return false;
                        }}
                        buttonImgUrl={props.appleImgUrl}
                    />,
                    <SignInFooter />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.VERIFY}>
                {narrowWrap(
                    <VerifyEmail
                        email={email}
                        anonUserRedirect={() => {
                            authRoutes.redirectToLogin(history);
                        }}
                    />,
                    <SimpleSigninFooter
                        linkHref={verifyPageLoginUrl}
                        linkId='js-verify-page-login-url'
                    />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.GOOGLE_SSO.SIGN_UP}>
                <GoogleSignup
                    firstName={ssoFirstName}
                    lastName={ssoLastName}
                    showEmailOptIn={props.showEmailOptIn}
                    localizedTOS={props.localizedTOS}
                    onSuccessfulSubmit={({url}) => {
                        window.location.href = url;
                    }}
                />
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.GOOGLE_SSO.LINK}>
                <LinkGoogleSsoForm
                    onClickBack={() => {
                        pushHistory(authRoutes.AUTH_ROUTES.SIGN_IN);
                        return false;
                    }}
                />
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.APPLE_SSO.SIGN_UP}>
                <AppleSignup
                    firstName={ssoFirstName}
                    lastName={ssoLastName}
                    onSuccessfulSubmit={({url}) => {
                        window.location.href = url;
                    }}
                    showEmailOptIn={props.showEmailOptIn}
                    localizedTOS={props.localizedTOS}
                />
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.APPLE_SSO.LINK}>
                <LinkAppleSsoForm
                    onClickBack={() => {
                        pushHistory(authRoutes.AUTH_ROUTES.SIGN_IN);
                        return false;
                    }}
                />
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.APPLE_SSO.RELAY_EMAIL_WARNING}>
                {narrowWrap(
                    <AppleSsoRelayEmailWarning
                        onClickCta={() => {
                            pushHistory(authRoutes.AUTH_ROUTES.APPLE_SSO.SIGN_UP);
                            return false;
                        }}
                    />,
                    <SignUpFooter />
                )}
            </Route>
            <Route path={authRoutes.AUTH_ROUTES.SSO_MUTEX}>
                {narrowWrap(
                    <SsoMutexPage
                        existingSsoProvider={assignedSso}
                        anonUserRedirect={() => {
                            authRoutes.redirectToLogin(history);
                        }}
                        onClickCta={() => {
                            pushHistory(authRoutes.AUTH_ROUTES.SIGN_IN);
                            return false;
                        }}
                    />,
                    <SignInFooter />
                )}
            </Route>
            <Route path='/'>
                <Redirect
                    to={{
                        pathname: authRoutes.AUTH_ROUTES.SIGN_IN,
                        search: currLocation.search,
                        state: {from: currLocation},
                    }}
                />
            </Route>
        </Switch>
    );
};

AuthMain.propTypes = {
    showEmailOptIn: PropTypes.bool.isRequired,
    localizedTOS: PropTypes.string.isRequired,
    googleClientId: PropTypes.string.isRequired,
    appleClientId: PropTypes.string.isRequired,
    showAppDownload: PropTypes.bool,
    showKitImage: PropTypes.bool,

    appleImgUrl: PropTypes.string.isRequired,
    appStoreImgUrl: PropTypes.string.isRequired,
    closeIconUrl: PropTypes.string.isRequired,
    googleImgUrl: PropTypes.string.isRequired,
    googlePlayImgUrl: PropTypes.string.isRequired,
    kitImgUrl: PropTypes.string.isRequired,
    resendOtpUrl: PropTypes.string.isRequired,
    starIconUrl: PropTypes.string.isRequired,
};

AuthMain.defaultProps = {
    showEmailOptIn: false,
    showAppDownload: false,
    showKitImage: false,
};

const AuthMainWrapper = (props) => {
    return (
        <Router>
            <AuthMain
                {...props}
            />
        </Router>
    );
};

export {AuthMain, AuthMainWrapper};
